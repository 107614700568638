import React from "react"
import SVG from "../assets/img/SVG";
import unBrav from "../assets/img/unbrav.png";
import {FiChevronRight} from "react-icons/fi";
import { withTranslation } from "react-i18next";
import {Player} from "video-react";

class Unbrav extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            animated: false
        }
    }
    componentDidUpdate() {
        if(!this.state.animated && this.props.anim){
            this.setState({
                animated: true
            })
        }
    }
    render(){
        const {t} = this.props;
        const {animated} = this.state;
        return (
            <div className="section" id="unbrav">
                <div className="container">
                    <div className="content">
                        <div className="section-logo">
                            <SVG type="UnBrav" className={animated ? "animate" : ""}/>
                        </div>
                    </div>
                </div>
                <div className="content full">
                    <div className="mockup">
                        <img src={unBrav} alt="UnBrav" className="top-left"/>
                    </div>
                    <div className="text right">
                        <h2>{t('unbrav-title')}</h2>
                        <p>{t('unbrav-text')}</p>
                        <a className="btn" href="https://unbrav.de" target="_blank">
                            {t('unbrav-visit')} <FiChevronRight/>
                        </a>
                    </div>
                </div>
                <div className="container">
                    <div className="video-wrapper">
                        <h3>{t('unbrav-in-motion')}</h3>
                        <div className="video">
                            <Player
                                playsInline
                                poster="/assets/video/unbrav-poster.jpg"
                                src="/assets/video/unbrav.mp4"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Unbrav);
