import React from "react"
import { withTranslation } from "react-i18next";

class Imprint extends React.Component{
    render(){
        const {t} = this.props;
        return (
            <div id="imprint" className="section">
                <div className="container">
                    <div className="content">
                        <h2>{t('imprint')}</h2>
                        <div>
                            <div>
                                <p className="big">The Visual Designers</p>
                                <p><strong>Christoph Herrmann</strong></p>
                            </div>
                            <div>
                                <p>UST-ID: DE274484281</p>
                            </div>
                            <p><a href="mailto:weare@thevisualdesigners.com">weare@thevisualdesigners.com</a></p>
                            <p><a href="https://thevisualdesigners.com">web: thevisualdesigners.com</a></p>
                        </div>
                        <div>
                            <div>
                                <p>Friedrichstrasse 123</p>
                                <p>10117 Berlin</p>
                                <p>Germany</p>
                            </div>
                            <div>
                                <p>120 High Road, East Finchley</p>
                                <p>N2 9ED</p>
                                <p>London, England</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Imprint)
