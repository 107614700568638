import React from "react";
import ReactWOW from 'react-wow'
import { IconContext } from "react-icons";
import{FiChevronDown} from "react-icons/fi";
import { withTranslation } from "react-i18next";

import SVG from "../assets/img/SVG"
import vanDerGeldern from "../assets/img/SVG/vandergeldern.svg"
import unBrav from "../assets/img/SVG/unBrav.svg"


class Welcome extends React.Component{
    render(){
        const {t} = this.props;
        return (
            <div className="section full-height" id="welcome">
                <div className="logo-wrapper">
                    <div className="top">
                        <SVG type="Logo" className="logo"/>
                        <SVG type="Line" className="line" color="#AAA"/>
                        <div className="logos">
                            <ReactWOW animation="fadeInDown" delay="2.2s">
                                <img src={vanDerGeldern} alt="Van der Geldern Logo"/>
                            </ReactWOW>
                            <ReactWOW animation="fadeInDown" delay="2.4s">
                                <img src={unBrav} alt="UnBrav Logo"/>
                            </ReactWOW>
                        </div>
                    </div>
                    <ReactWOW animation="fadeInDown" duration="1s" delay="3.5s">
                        <div className="explore" onClick={() => this.props.scrollToId("van-der-geldern", 1)}>
                            <span>{t('explore')}</span>
                            <div className="icon">
                                <IconContext.Provider value={{ color: "#AAA", style: { strokeWidth: '1px', width: '40px', height: '30px' } }}>
                                    <FiChevronDown />
                                </IconContext.Provider>
                            </div>
                        </div>
                    </ReactWOW>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Welcome);
