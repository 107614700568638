import React from "react"

import Logo from "./Logo";
import Line from "./Line";
import VanDerGeldern from "./VanDerGeldern";
import UnBrav from "./UnBrav";
import HeadlineBrush from "./HeadlineBrush";
import Burger from "./Burger";

const SVG = (props) => {
    switch (props.type){
        case "Logo":
            return(
                <Logo {...props}/>
            )
        case "Line":
            return(
                <Line {...props}/>
            )
        case "VanDerGeldern":
            return(
                <VanDerGeldern {...props}/>
            )
        case "UnBrav":
            return(
                <UnBrav {...props}/>
            )
        case "HeadlineBrush":
            return(
                <HeadlineBrush {...props}/>
            )
        case "Burger":
            return(
                <Burger {...props}/>
            )
        default:
            return (
                ""
            );
    }
}

export default SVG
