import React from "react";
import SVG from "../assets/img/SVG";
import vanDerGeldern from "../assets/img/vdg-mockup-1.png";
import{FiChevronRight} from "react-icons/fi";
import { withTranslation } from "react-i18next";
import { Player } from 'video-react';


class VanDerGeldern extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            animated: false
        }
    }
    componentDidUpdate() {
        if(!this.state.animated && this.props.anim){
            this.setState({
                animated: true
            })
        }
    }

    render() {
        const {t} = this.props;
        const {animated} = this.state;
        return (
            <div className="section" id="van-der-geldern">
                <div className="container">
                    <div className="content">
                        <div className="section-logo">
                            <SVG type="VanDerGeldern" className={animated ? "animate" : ""}/>
                        </div>
                    </div>
                </div>
                <div className="content full">
                    <div className="text left">
                        <h2>{t('vdg-title')}</h2>
                        <p>{t('vdg-text')}</p>
                        <a className="btn" href="https://vandergeldern.com" target="_blank">
                            {t('vdg-visit')} <FiChevronRight/>
                        </a>
                    </div>
                    <div className="mockup">
                        <img src={vanDerGeldern} alt="Van der Geldern" className="top-left"/>
                    </div>
                </div>
                <div className="container">
                    <div className="video-wrapper">
                        <h3>{t('vdg-in-motion')}</h3>
                        <div className="video">
                            <Player
                                playsInline
                                poster="/assets/video/vdg-poster.jpg"
                                src="/assets/video/vdg.mp4"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(VanDerGeldern);
