import React from "react"
import ContactForm from "./ContactForm"

class Contact extends React.Component {
    render() {
        return (
            <div className="section" id="contact">
                <div className="background"/>
                <div className="container">
                    <div className="content">
                        <div className="legal"/>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;