import React from "react"

const Line = (props) => {
    const color = props.color || "#CCC";
    return (
        <svg viewBox="0 0 444.69 1" {...props}>
            <defs>
                <linearGradient
                    id="gradient"
                    y1={0.5}
                    x2={444.69}
                    y2={0.5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor={color} stopOpacity={0} />
                    <stop offset={0.5} stopColor={color} />
                    <stop offset={1} stopColor={color} stopOpacity={0} />
                </linearGradient>
            </defs>
            <path
                fill="none"
                id="line"
                strokeMiterlimit={10}
                stroke="url(#gradient)"
                d="M0 .5h444.69"
            />
        </svg>
    )
}

export default Line
