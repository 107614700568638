import React from "react";
import {FiFacebook, FiTwitter} from "react-icons/fi";
import { withTranslation } from "react-i18next";
import DataProtection from "./DataProtection";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDataProtection: false
        }
        this.toggleDataProtection = this.toggleDataProtection.bind(this);
    }
    toggleDataProtection(){
        this.setState({
            showDataProtection: !this.state.showDataProtection
        })
    }
    render() {
        const {t} = this.props;
        const {showDataProtection} = this.state;
        return (
            <div id="footer" className="section">
                {showDataProtection?
                <DataProtection toggleDataProtection={this.toggleDataProtection}/>:""}
                <div className="container">
                    <div className="socials">
                        <span className="icon"><FiFacebook/></span>
                        <span className="icon"><FiTwitter/></span>
                    </div>
                    <div className="content">
                        <div>
                            <h3>{t('navigate')}</h3>
                            <ul>
                                <li><span>{t('welcome')}</span></li>
                                <li><span>{t('van-der-geldern')}</span></li>
                                <li><span>{t('unbrav')}</span></li>
                                <li><span>{t('contact')}</span></li>
                            </ul>
                        </div>
                        <div>
                            <h3>{t('visit-us')}</h3>
                            <ul>
                                <li><span>{t('van-der-geldern')}</span></li>
                                <li><span>{t('unbrav')}</span></li>
                            </ul>
                        </div>
                        <div>
                            <h3>{t('legal')}</h3>
                            <ul>
                                <li><span>{t('imprint')}</span></li>
                                <li><span onClick={() => this.toggleDataProtection()}>{t('data-protection')}</span></li>
                            </ul>
                        </div>
                        <div>
                            <h3>{t('get-in-touch')}</h3>
                            <ul>
                                <li><span>weare@thevisualdesigners.com</span></li>
                                <li><span>{t('contact')}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Footer);