import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

import "video-react/dist/video-react.css";
import './assets/css/style.scss';

ReactDOM.render(
    <I18nextProvider i18n={i18n} t={i18n.t}>
        <App />
    </I18nextProvider>,
  document.getElementById('vd-app')
);
serviceWorker.register();
